import React, { useCallback, useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { HiPlus } from 'react-icons/hi';
import { IOption } from '~/components/Select';

import api from '~/services/api';

import { Container } from './styles';

import { IModalityData } from '../../Modalities';
import Category from './Category';

interface ICategory {
  id: number;
  name: string;
}

export interface ICategoryData {
  input_id: number;
  id?: number;
  name?: string;
}

interface IModality {
  typeSelected?: number;
  data: IModalityData;
  onChange?(data: ICategoryData[]): void;
}

const Modality: React.FC<IModality> = ({ data, typeSelected, onChange }) => {
  const [categories, setCategories] = useState<IOption[]>([]);
  const [categoriesData, setCategoriesData] = useState<ICategoryData[]>([
    {
      input_id: new Date().getTime(),
    },
  ]);

  useEffect(() => {
    if (data.categories) {
      setCategoriesData(data.categories);
    }
  }, [data]);

  const handleLoadCategories = useCallback(
    async (search = '') => {
      const response = await api.get<ICategory[]>('categories', {
        params: { search, type: typeSelected },
      });
      const dataCategories = response.data.map<IOption>((category) => ({
        id: category.id,
        value: category.name,
        selected: false,
      }));
      setCategories(dataCategories);
    },
    [typeSelected]
  );

  useEffect(() => {
    handleLoadCategories();
  }, [handleLoadCategories]);

  const handleAddCategory = useCallback(() => {
    setCategoriesData((oldState) => [
      ...oldState,
      {
        input_id: new Date().getTime(),
      },
    ]);
  }, []);

  const handleRemoveCategory = useCallback(
    (input_id) => {
      const newCategoriesData = categoriesData.filter(
        (modality) => modality.input_id !== input_id
      );
      if (newCategoriesData.length === 0) {
        newCategoriesData.push({
          input_id: new Date().getTime(),
        });
      }
      if (onChange) {
        onChange(newCategoriesData);
      }
      setCategoriesData(newCategoriesData);
    },
    [categoriesData, onChange]
  );

  useEffect(() => {
    if (categoriesData.length === 0) {
      setCategoriesData([
        {
          input_id: new Date().getTime(),
        },
      ]);
    }
  }, [categoriesData]);

  const handleChangeCategory = useCallback(
    (value, categoryIndex) => {
      const newCategoriesData = categoriesData.slice();
      newCategoriesData[categoryIndex].name = value;
      handleLoadCategories(value);
    },
    [handleLoadCategories, categoriesData]
  );

  const handleSelectCategory = useCallback(
    (categorySelected, categoryIndex) => {
      const newCategoriesData = categoriesData.slice();
      newCategoriesData[categoryIndex].id = categorySelected.id;
      newCategoriesData[categoryIndex].name = categorySelected.value;
      setCategoriesData(newCategoriesData);
      if (onChange) {
        onChange(newCategoriesData);
      }
    },
    [categoriesData, onChange]
  );

  const handleClickAddNewCategory = useCallback(
    async (categoryIndex) => {
      const newCategoriesData = categoriesData.slice();
      const formData = {
        type_id: typeSelected,
        name: newCategoriesData[categoryIndex].name,
      };
      const response = await api.post('categories', formData);
      newCategoriesData[categoryIndex].id = response.data.id;
      setCategoriesData(newCategoriesData);
      if (onChange) {
        onChange(newCategoriesData);
      }
    },
    [categoriesData, onChange, typeSelected]
  );

  const handleFocusCategory = useCallback(
    (e) => {
      if (e.target.value && e.target.value !== 'Selecione') {
        handleLoadCategories(e.target.value);
      }
    },
    [handleLoadCategories]
  );

  const handleBlurCategory = useCallback(() => {
    setTimeout(() => {
      handleLoadCategories();
    }, 200);
  }, [handleLoadCategories]);

  return (
    <Container>
      <div className="bg-category p-3 mb-2">
        <h5 className="roboto h5 fw-semibold">{data.name}</h5>
        <p className="mb-0">
          Mínimo de 1 categoria <MdCheck size={16} color="#8c8c8c" />
        </p>
      </div>
      {categoriesData.map((category, index) => (
        <Category
          categories={categories}
          selectedCategory={category}
          onSelectCategory={(option) => handleSelectCategory(option, index)}
          onChangeCategory={(value) => handleChangeCategory(value, index)}
          onFocus={handleFocusCategory}
          onBlur={handleBlurCategory}
          onClickAddNew={() => handleClickAddNewCategory(index)}
          onRemoveCategory={() => handleRemoveCategory(category.input_id)}
        />
      ))}
      <button
        type="button"
        className="d-flex align-items-center bd-modality bg-transparent p-2 w-100 mb-3"
        onClick={handleAddCategory}
      >
        <p className="w-100 h6 fw-normal btn-add-modality border-0 d-flex align-items-center mb-0">
          Adicionar nova categoria
        </p>
        <div className="border-0 bg-transparent h-100">
          <HiPlus size={16} color="#D4D4D4" className="ms-2 " />{' '}
        </div>
      </button>
    </Container>
  );
};

export default Modality;
