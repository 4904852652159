import styled from 'styled-components';

export const Container = styled.div`
  .textarea .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0px 0px;
  }

  .textarea .ql-container.ql-snow {
    border-radius: 0px 0px 4px 4px;
  }

  .textarea .ql-editor {
    height: 200px;
  }

  .bg-questions {
    background-color: #f6f6f6;
    border-radius: 8px;
  }

  .btn-title {
    color: #b9612f;
  }

  .bg-white {
    border-radius: 8px;
  }

  .btn-add-aswer {
    color: #777;
  }

  .checkbox label {
    border: none !important;
    padding: 0px !important;
    span {
      border: none !important;
      color: #202020;
    }
  }

  .answers {
    border: 1px solid #c8c8c8;
  }

  .input input::placeholder {
    color: #d4d4d4;
  }

  .grab {
    cursor: grab;
  }

  .grab:active {
    cursor: grabbing;
  }

  .hr-question {
    background: #e9b89c;
    opacity: 1;
    height: 1px;
    margin: 0 10px;
    flex: 1;
  }
`;
