/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

interface IData {
  [key: string]: any;
}

interface IAllData {
  data: IData[];
  columns: IData[];
  fileName?: string;
  className?: string;
  onClick?: () => Promise<IData[]>;
}

const ExportCsv: React.FC<IAllData> = ({
  columns,
  fileName,
  className,
  onClick,
}) => {
  const [allData, setAllData] = useState<IData[]>([]);
  const updatedData = useMemo(() => {
    const aux = allData.map((item: any) => {
      const newItem: { [key: string]: string } = {};
      Object.entries(item).forEach(([key, value], index) => {
        const columnSelected = columns.find(
          (column) => column.selector === key
        );
        if (columnSelected) {
          if (!columnSelected.name) {
            newItem[`Extra ${String.fromCharCode(65 + index - 10)}`] =
              value as any;
          } else {
            newItem[columnSelected.name] = value as any;
          }
        }
      });
      return newItem;
    });

    return aux;
  }, [columns, allData]);

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      if (onClick) {
        const newData = await onClick();
        setAllData(newData);

        const element = document.getElementById('csv-link');
        if (element) {
          element.click();
        }
      }
    },
    [onClick]
  );

  return (
    <>
      <button
        type="button"
        className={`${className} mt-3 mt-sm-0 mb-0 me-lg-4 h6 fw-normal text-white btn-new px-5 py-2`}
        onClick={handleClick}
      >
        Exportar .CSV
      </button>
      {updatedData.length > 0 && (
        <CSVLink
          id="csv-link"
          data={updatedData}
          filename={fileName}
          className="d-none"
        />
      )}
    </>
  );
};

export default ExportCsv;
