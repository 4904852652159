import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';

interface IColorBox {
  color: string;
  selected?: boolean;
}

interface IImg {
  src: string;
}

export const Container = styled.div`
  min-width: calc(100% - 88px);

  button:disabled {
    cursor: not-allowed;
  }

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  h2,
  h3 {
    font-family: 'Roboto';
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
    height: 4px;
    border-radius: 4px;
  }

  a {
    :hover {
      color: unset;
    }
  }

  p {
    font-family: 'Roboto';
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 400;
  }

  label {
    padding: 64px 30px;
    border-radius: 20px;
    border: 1px dashed #ff6900;
  }

  label {
    span:nth-child(2) {
      font-family: 'Roboto';
      color: #8c8c8c;
      font-size: 16px;
      font-weight: 400;
    }

    span:nth-child(3) {
      border-radius: 4px;
      border: 1px solid #ff6900;
      color: #ff6900;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 16px;
    }

    span:nth-child(4) {
      color: #8c8c8c;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
    }
  }

  .tooltip-total:after {
    background-color: #002f6c;
    color: #fff;
    border: 1px solid #002f6c;
    border-radius: 15px;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 350px;
    z-index: 10000;
  }

  .tooltip-total:hover:after {
    display: block;
  }

  .textarea-description {
    border-radius: 4px;
    border: 1.5px solid #cbccce;
    padding: 5px 10px;
    height: 200px;
    overflow: auto;

    h2 {
      font-size: 1.5rem;
      line-height: 1.2;
    }

    p {
      font-family: 'Roboto';
      color: #8c8c8c;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.42;
      margin-bottom: 0;
    }
  }

  .check-payments label {
    margin: 0;
    margin-right: 20px;
    padding: 0;
    border: none;

    span {
      color: #202020;
      border: none;
      padding: 8px 0;
    }
  }

  iframe {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .bg-questions {
    background-color: #f6f6f6;
    border-radius: 8px;
  }

  .btn-title {
    color: #b9612f;
  }

  .bg-white {
    border-radius: 8px;
  }

  .btn-add-aswer {
    color: #777;
  }

  .checkbox label {
    border: none !important;
    padding: 0px !important;
    span {
      border: none !important;
      color: #202020;
    }
  }

  .answers {
    border: 1px solid #c8c8c8;
  }

  .input input::placeholder {
    color: #d4d4d4;
  }

  .bd-lots {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .all-lots {
    width: 100%;
  }

  .all-lots > h3:nth-child(1),
  .all-lots > div:nth-child(2),
  .all-lots > div:nth-child(3) {
    width: 33.33%;
  }

  .bg-category {
    background: #ffe9da;
    border-radius: 8px;
  }

  .w-h3 {
    width: 95px;
  }

  .or {
    color: #c2c2c2;
  }

  .btn-add-modality {
    color: #d4d4d4;
  }

  .btn-add-lot {
    border-radius: 4px;
    color: #d4d4d4;
    border: 1px solid #e1e1e1;
  }

  .roboto {
    font-family: 'Roboto';
  }

  .poppins {
    font-family: 'Poppins';
  }

  .bd-modality {
    border-radius: 4px;
    border: 1px solid #efefef;
    line-height: 24px;

    input {
      font-size: 1rem;
      font-weight: 600;
      font-family: Roboto;
      color: #202020;

      ::placeholder {
        font-weight: 400;
      }
    }
  }

  .btn-add {
    border-radius: 8px;
    border: 1px solid #ff842f;
    background-color: #fff;
    color: #ff6900;
    min-height: 305px;
  }

  .arquives {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .arquives img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #002f6c;
    object-fit: contain;
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-color + .btn-color {
    margin-left: 5px;
  }

  .w-card-summary {
    min-width: 310px;

    > div {
      width: max-content;

      .kits {
        width: 256px;

        + .kits {
          margin-left: 16px;
        }
      }
    }
  }

  .btn-photo-prod {
    position: relative;
    color: #8c8c8c;
    border: none;
  }

  .btn-photo-prod-active {
    position: relative;
    color: #8c8c8c;
    border: none;
    -webkit-text-stroke-color: #292623;
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: #8c8c8c;
    :before {
      content: '';
      position: absolute;
      bottom: -5px;
      width: 75%;
      height: 2px;
      background-color: #ff6900;
    }
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
    width: 200px;
    line-height: 24px;
  }

  .bg-white {
    border-radius: 8px;
  }

  .w-11 {
    width: 11%;
  }

  .text {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 100%);
    font-weight: 500;
  }

  .text-active {
    color: #002f6c;
  }

  .circle {
    width: 40px;
    height: 40px;
    border: 1px solid #8c8c8c;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .circle-active {
    width: 40px;
    height: 40px;
    border: 1px solid #002f6c;
    background-color: #002f6c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #fff;
  }

  .hr-active {
    background: #002f6c;
    opacity: 1;
    height: 4px;
    border-radius: 4px;
  }

  .input {
    height: 37px;
    border-radius: 4px;
    border: 1px solid #d3d3d3 !important;
  }

  input {
    color: #5d5d5d;
  }

  .w-tam {
    width: 32px;
    span {
      color: #e1e1e1;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      border: 1px solid #e1e1e1;
      padding: 3px 0;
      text-align: center;
    }

    span.active {
      color: #8c8c8c;
      border: 1px solid #8c8c8c;
    }
  }

  .kits {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .btn-close-card {
    right: 5px;
    top: 5px;
  }
`;

export const Img = styled.div<IImg>`
  width: 182px;
  height: 223px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
`;

export const ColorBox = styled.div<IColorBox>`
  min-width: 24px;
  min-height: 24px;
  border-radius: 4px;
  background: ${(props) => props.color};
  border: 2px solid
    ${(props) => (props.selected ? lighten(0.251, props.color) : 'transparent')};
  transition-duration: 0.3s;
`;

export const Modal = styled(ModalComponent)`
  backdrop-filter: blur(3px);

  .title {
    color: #000;
    font-size: 26px;
    font-weight: 500;
  }

  h3 {
    font-size: 26px;
    font-weight: 500;
  }

  label {
    span {
      color: #000;
    }
  }

  .input {
    border: 1px solid #d3d3d3 !important;

    input {
      color: #202020;
    }
  }

  span:not(.h6) {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
  }

  p:not(.h6) {
    color: #878787;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }

  .arquives {
    border-radius: 8px;
    border: 1px solid #e1e1e1;
  }

  .arquives img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    border: 1px solid #002f6c;
    object-fit: contain;
  }

  .label-modal {
    padding: 34px 30px;
    border-radius: 20px;
    border: 1px dashed #ff6900;
  }

  .label-modal {
    span:nth-child(2) {
      font-family: 'Roboto';
      color: #8c8c8c;
      font-size: 16px;
      font-weight: 400;
    }

    span:nth-child(3) {
      border-radius: 4px;
      border: 1px solid #ff6900;
      color: #ff6900;
      font-size: 16px;
      font-weight: 400;
      padding: 8px 16px;
    }

    span:nth-child(4) {
      color: #8c8c8c;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
    }
  }

  .variations {
    .variation-row {
      > div:first-child {
        width: 50px;

        .size {
          width: 32px;
          height: 32px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px !important;
          border: 1px solid #ff842f !important;
          color: #ff842f !important;
          background-color: #fff;
          margin: 0 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > div:last-child {
        width: calc(100% - 50px);
      }

      + .variation-row {
        margin-top: 10px;
      }
    }
  }

  .height {
    height: 37px;
    border-radius: 4px;
  }

  .btn-filter {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .modal-content {
    border-radius: 8px;
    border: none;
  }

  .add-new-color {
    color: #d4d4d4;
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
  }
`;
