import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';

import { IoCheckmark } from 'react-icons/io5';
import Toast from '~/utils/toast';

import { Container } from './styles';
import { KrClose, KrTrash } from '~/components/KoroIcons';
import Input from '~/components/Input';
import InputCheckbox from '~/components/InputCheckbox';

export interface IAlternative {
  id: number;
  text: string;
  new: boolean;
}

export interface IQuestion {
  id: number;
  question: string;
  type: 'unique-choice' | 'multiple-choice' | 'free-text';
  alternatives?: IAlternative[];
  isRequired?: boolean;
  order: number;
}

interface IQuestionsProps {
  onChange(questions: IQuestion[]): void;
  questions?: IQuestion[];
}

const Questions: React.FC<IQuestionsProps> = ({
  onChange,
  questions: dataQuestions,
}) => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  useEffect(() => {
    onChange(questions);
  }, [questions, onChange]);

  useEffect(() => {
    if (dataQuestions && questions.length === 0) {
      setQuestions(dataQuestions);
    }
  }, [dataQuestions, questions.length]);

  const handleClickNewQuestion = useCallback(
    (type) => {
      setQuestions([
        ...questions,
        {
          id: questions.length + 1,
          question: '',
          type,
          isRequired: true,
          alternatives:
            type === 'multiple-choice' || type === 'unique-choice'
              ? [
                  {
                    id: new Date().getTime(),
                    text: '',
                    new: true,
                  },
                ]
              : undefined,
          order: questions.length + 1,
        },
      ]);
    },
    [questions]
  );

  const handleChangeAlternative = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      question_id: number,
      alternative_id: number
    ) => {
      const newQuestion = questions.slice();
      const questionIndex = newQuestion.findIndex(
        (question) => question.id === question_id
      );

      if (questionIndex >= 0) {
        const { alternatives } = newQuestion[questionIndex];
        if (alternatives) {
          const alternativeIndex = alternatives.findIndex(
            (alternative) => alternative.id === alternative_id
          );
          if (alternativeIndex >= 0) {
            alternatives[alternativeIndex].text = e.target.value;
          } else {
            alternatives.push({
              id: new Date().getTime(),
              text: e.target.value,
              new: true,
            });
          }

          newQuestion[questionIndex].alternatives = alternatives;
        } else {
          newQuestion[questionIndex].alternatives = [
            {
              id: new Date().getTime(),
              text: e.target.value,
              new: true,
            },
          ];
        }
      }

      setQuestions(newQuestion);
    },
    [questions]
  );

  const handleClickAddAlternative = useCallback(
    (questionIndex) => {
      const newQuestions = questions.slice();

      newQuestions[questionIndex].alternatives?.push({
        id: new Date().getTime(),
        text: '',
        new: true,
      });

      setQuestions(newQuestions);
    },
    [questions]
  );

  const handleQuestionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, questionId: number) => {
      const questionText = e.target.value;

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question.id === questionId) {
            return {
              ...question,
              question: questionText,
            };
          }
          return question;
        })
      );
    },
    []
  );

  const handleDeleteQuestion = useCallback(
    (questionId: number) => {
      Swal.fire({
        icon: 'warning',
        iconColor: '#ff6900',
        title: `Excluir pergunta?`,
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim',
        confirmButtonColor: '#ff6900',
        cancelButtonColor: '#B34A00',
        reverseButtons: true,
      }).then((e) => {
        if (e.isConfirmed) {
          let newQuestions = questions.filter(
            (question) => question.id !== questionId
          );

          newQuestions = newQuestions.map((question, index) => ({
            ...question,
            order: index + 1,
          }));

          setQuestions(newQuestions);
          Toast.fire({
            icon: 'success',
            iconColor: '#ff6900',
            title: 'Pergunta excluída!',
          });
        }
      });
    },
    [questions]
  );

  const handleClickSaveAlternative = useCallback(
    (questionIndex: number, alternativeId: number) => {
      const newQuestions = questions.slice();

      const { alternatives } = newQuestions[questionIndex];

      if (alternatives) {
        const alternativeIndex = alternatives.findIndex(
          (alternative) => alternative.id === alternativeId
        );

        if (alternativeIndex >= 0) {
          alternatives[alternativeIndex].new = false;
        }

        newQuestions[questionIndex].alternatives = alternatives;
      }

      setQuestions(newQuestions);
    },
    [questions]
  );

  const handleDeleteAlternative = useCallback(
    (questionIndex: number, alternativeId: number) => {
      const newQuestions = questions.slice();

      let alternatives = newQuestions[questionIndex].alternatives?.filter(
        (alternative) => alternative.id !== alternativeId
      );

      alternatives = alternatives?.map((alternative, index) => ({
        ...alternative,
        order: index + 1,
      }));

      newQuestions[questionIndex].alternatives = alternatives;

      setQuestions(newQuestions);
    },
    [questions]
  );

  const handleCheckChange = useCallback((e, questionIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            isRequired: e[0].selected,
          };
        }
        return question;
      })
    );
  }, []);

  const reorderQuestions = useCallback(
    (questionsData, startIndex, endIndex) => {
      const reorderedQuestions = [...questionsData];
      const [removed] = reorderedQuestions.splice(startIndex, 1);
      reorderedQuestions.splice(endIndex, 0, removed);

      const newQuestions = reorderedQuestions.map((question, index) => ({
        ...question,
        order: index + 1,
      }));

      return newQuestions;
    },
    []
  );

  const handleChangeOrder = useCallback(
    (result) => {
      if (!result.destination) return;
      const newQuestions = reorderQuestions(
        questions,
        result.source.index,
        result.destination.index
      );
      setQuestions(newQuestions);
    },
    [questions, reorderQuestions]
  );

  return (
    <Container className="bg-questions p-3">
      <h4>Perguntas para os atletas (Opcional)</h4>
      <p className="mb-4">
        Faça perguntas importantes e obrigatórias para seus atletas, te ajudando
        na organização do evento.
      </p>
      <div className="row justify-content-between">
        <div className="col-lg-5">
          <div className="bg-white p-3">
            <h6 className="mb-3">Adicionar pergunta:</h6>
            <button
              type="button"
              onClick={() => handleClickNewQuestion('multiple-choice')}
              className="btn-title border-0 bg-transparent d-flex align-items-center mb-2"
            >
              <FiPlus size={16} color="#FF842F" className="me-2" /> Múltipla
              Escolha
            </button>
            <button
              type="button"
              onClick={() => handleClickNewQuestion('unique-choice')}
              className="btn-title border-0 bg-transparent d-flex align-items-center mb-2"
            >
              <FiPlus size={16} color="#FF842F" className="me-2" /> Escolha
              Única
            </button>
            <button
              type="button"
              onClick={() => handleClickNewQuestion('free-text')}
              className="btn-title border-0 bg-transparent d-flex align-items-center"
            >
              <FiPlus size={16} color="#FF842F" className="me-2" /> Resposta
              Curta (por escrito)
            </button>
          </div>
        </div>
        <div className="col-lg-6">
          <DragDropContext onDragEnd={handleChangeOrder}>
            <Droppable droppableId="questions">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questions.map((question, index) => (
                    <Draggable
                      draggableId={question.id.toString()}
                      key={question.id}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="bg-white border cursor-grab p-3 mb-3"
                        >
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="btn-title mb-0">
                              {question.type === 'free-text' &&
                                'Resposta Curta (por escrito)'}

                              {question.type === 'multiple-choice' &&
                                'Múltipla Escolha'}
                              {question.type === 'unique-choice' &&
                                'Escolha Única'}
                            </h6>{' '}
                            <hr className="hr-question" />
                            <button
                              type="button"
                              onClick={() => handleDeleteQuestion(question.id)}
                              className="border-0 bg-transparent"
                              title="Excluir pergunta"
                            >
                              <KrTrash size={16} color="#F22525" />
                            </button>
                          </div>
                          <h6>Pergunta:</h6>
                          <div className="mb-3">
                            <Input
                              type="text"
                              className="input"
                              placeholder="Digite aqui"
                              name={`question_${question.id}`}
                              onChange={(e) =>
                                handleQuestionChange(e, question.id)
                              }
                              value={question.question}
                            />
                            {question.type === 'free-text' && (
                              <InputCheckbox
                                type="checkbox"
                                name={`required_${index}`}
                                className="checkbox justify-content-start"
                                options={[
                                  {
                                    value: 'required',
                                    label: 'Resposta obrigatória',
                                    selected: question.isRequired,
                                  },
                                ]}
                                onChange={(e) => handleCheckChange(e, index)}
                              />
                            )}
                          </div>
                          {question.type !== 'free-text' && (
                            <h6>{`Opções de resposta${
                              question.type === 'multiple-choice' ? 's' : ''
                            }:`}</h6>
                          )}
                          {question.type !== 'free-text' &&
                            question.alternatives?.map((alternative) => (
                              <div key={alternative.id}>
                                {alternative.new ? (
                                  <div className="mt-3 d-flex">
                                    <Input
                                      type="text"
                                      className="input"
                                      placeholder="Digite aqui"
                                      value={alternative.text}
                                      onChange={(e) =>
                                        handleChangeAlternative(
                                          e,
                                          question.id,
                                          alternative.id
                                        )
                                      }
                                      name="alternative"
                                    />
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleClickSaveAlternative(
                                          index,
                                          alternative.id
                                        )
                                      }
                                      className="border-0 bg-transparent ms-2"
                                      disabled={!alternative.text}
                                    >
                                      <IoCheckmark size={24} color="#FF6900" />
                                    </button>
                                    <button
                                      title="Excluir alternativa"
                                      type="button"
                                      onClick={() =>
                                        handleDeleteAlternative(
                                          index,
                                          alternative.id
                                        )
                                      }
                                      className="border-0 bg-transparent ms-2"
                                    >
                                      <KrClose size={12} color="#F22525" />
                                    </button>
                                  </div>
                                ) : (
                                  <div className="answers d-flex align-items-center justify-content-between rounded-pill mb-2 px-3 py-2">
                                    {alternative.text}
                                    <button
                                      title="Excluir alternativa"
                                      type="button"
                                      onClick={() =>
                                        handleDeleteAlternative(
                                          index,
                                          alternative.id
                                        )
                                      }
                                      className="border-0 bg-transparent ms-2"
                                    >
                                      <KrClose size={12} color="#F22525" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                          {question.type !== 'free-text' && (
                            <button
                              type="button"
                              onClick={() => handleClickAddAlternative(index)}
                              className="btn-add-aswer w-100 border-0 rounded-pill py-2 mt-2"
                            >
                              <FiPlus
                                size={17}
                                color="#777777"
                                className="me-2"
                              />{' '}
                              Adicionar
                            </button>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </Container>
  );
};

export default Questions;
