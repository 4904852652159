/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { TableList, Container, Filter, Modal, TableListModal } from './styles';

import Table, { IColumn } from '~/components/Table';
import FiltersLists from '~/components/FiltersLists';
import ExportCsv from '~/components/ExportCsv';
import api from '~/services/api';
import { KrClose, KrEye } from '~/components/KoroIcons';

interface IAnswer {
  id: number;
  text: string;
  question: {
    name: string;
    order: number;
  };
}

interface ITicketData {
  id: number;
  user_id: number;
  code: number;
  document: string;
  email: string;
  name: string;
  status: string;
  modality?: {
    id: number;
    name: string;
  };
  batchCategory: {
    id: number;
    batch: {
      event: {
        id: number;
        name: string;
        description: string;
        date: string;
        type: {
          name: string;
        };
        event: {
          date: string;
        };
        address: {
          street: string;
          number: number;
          neighborhood: string;
          city: string;
          state: string;
          complement: string;
          zip_code: string;
        };
      };
    };
    category: {
      name: string;
    };
  };
  orderItem: {
    order: {
      status: string;
      code: number;
      payment: {
        qr_code?: string;
        payment_type: string;
      };
      created_at: string;
      user: {
        name: string;
        email: string;
        additionalInformation: {
          phone: string;
        };
      };
    };
  };
  kitUser?: {
    color?: {
      name: string;
    };
    size?: {
      size: string;
    };
  };
  created_at: string;
  answers: IAnswer[];
}

interface IQuestion {
  id: number;
  question: string;
  answer: string;
}

interface ITicket {
  id: string;
  name: string;
  document: string;
  email: string;
  event_id: number;
  event_name: string;
  event_date: string;
  modality?: string;
  category: string;
  kit: string;
  questions: IQuestion[];
}

interface IDataItem {
  id: string;
  name: string;
  document: string;
  email: string;
  event_id: number;
  event_name: string;
  event_date: string;
  modality?: string;
  category: string;
  kit: string;
  [key: string]: any;
}

const Users: React.FC = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData] = useState({
    from: 0,
    to: 0,
    total: 0,
    totalPage: 0,
  });
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [statusSelected, setStatusSelected] = useState<string | undefined>();
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [show, setShow] = useState(false);

  const handleClick = useCallback((questionsData) => {
    setQuestions(questionsData);
    setShow(true);
  }, []);

  const columnsModal = useMemo<IColumn[]>(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: false,
      },

      {
        name: 'Perguntas',
        selector: 'question',
        sortable: false,
      },
      {
        name: 'Respostas',
        selector: 'answer',
        sortable: false,
      },
    ],
    []
  );

  const status = useMemo(
    () => [
      {
        id: 'Disponível',
        value: 'Não Atribuido',
        selected: true,
      },
      {
        id: 'Usado',
        value: 'Atribuido',
        selected: false,
      },
    ],
    []
  );

  const handleLoadTickets = useCallback(async (page, statusData?: string) => {
    const response: AxiosResponse<ITicketData[]> = await api.get(
      `tickets/events/organizer`,
      {
        params: {
          // page,
          status: statusData ? [statusData] : [],
          allEventsStatus: true,
        },
      }
    );

    const dataTicket = response.data.map((ticket) => {
      const questionsData = ticket.answers.map<IQuestion>((answer) => {
        return {
          id: answer.question.order,
          question: answer.question.name,
          answer: answer.text,
        };
      });
      return {
        id: `#${ticket.code}`,
        name: ticket.name || '-',
        document: ticket.document || '-',
        email: ticket.email || '-',
        event_id: ticket.batchCategory.batch.event.id,
        event_name: ticket.batchCategory.batch.event.name,
        event_date: format(
          parseISO(ticket.batchCategory.batch.event.date),
          'dd/MM/yyyy'
        ),
        modality: ticket.modality?.name,
        category: ticket.batchCategory.category.name,
        kit: ticket.kitUser
          ? `${
              ticket.kitUser.color ? `Cor: ${ticket.kitUser.color.name}, ` : ''
            }${
              ticket.kitUser.size ? `Tamanho: ${ticket.kitUser.size.size}` : ''
            }`
          : 'Não Possui',
        buy_date: format(parseISO(ticket.created_at), 'dd/MM/yyyy'),
        buyer_name: ticket.orderItem.order.user.name,
        buyer_phone: ticket.orderItem.order.user.additionalInformation.phone,
        buyer_email: ticket.orderItem.order.user.email,
        questions: questionsData,
      };
    });

    setTickets(dataTicket);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    handleLoadTickets(1);
  }, [handleLoadTickets]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Documento',
        selector: 'document',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Id do evento',
        selector: 'event_id',
      },
      {
        name: 'Nome do evento',
        selector: 'event_name',
      },
      {
        name: 'Data do evento',
        selector: 'event_date',
      },
      {
        name: 'Modalidade',
        selector: 'modality',
      },
      {
        name: 'Categoria',
        selector: 'category',
      },
      {
        name: 'Kit',
        selector: 'kit',
      },
      {
        name: 'Data compra',
        selector: 'buy_date',
      },
      {
        name: 'Nome comprador',
        selector: 'buyer_name',
      },
      {
        name: 'Fone comprador',
        selector: 'buyer_phone',
      },
      {
        name: 'email comprador',
        selector: 'buyer_email',
      },
      {
        name: 'Perguntas',
        selector: 'questions',
        cell: (row: ITicket) => (
          <button
            type="button"
            onClick={() => handleClick(row.questions)}
            disabled={row.questions.length === 0}
            className={`${
              row.questions.length > 0 ? 'visible' : 'opacity-50'
            } btn-icons border-0`}
          >
            <KrEye size={16} color="#fff" />
          </button>
        ),
      },
    ],
    [handleClick]
  );

  const handleChangePage = useCallback(
    (page) => {
      handleLoadTickets(page, statusSelected);
      setPageSelected(page);
    },
    [handleLoadTickets, statusSelected]
  );

  const handleSubmitFilters = useCallback(
    (formData) => {
      setStatusSelected(
        formData.statusTicket !== 'todos' ? formData.statusTicket : undefined
      );
      handleLoadTickets(
        1,
        formData.statusTicket !== 'todos' ? formData.statusTicket : undefined
      );
    },
    [handleLoadTickets]
  );

  const dataCsv = useMemo(() => {
    const data = tickets.map<IDataItem>((ticket) => {
      const questionsData = ticket.questions.reduce((acc, question, index) => {
        acc[`questao ${index.toString().padStart(2, '0')}`] = `Q ${question.id
          .toString()
          .padStart(2, '0')}`;
        acc[`resposta ${index.toString().padStart(2, '0')}`] = question.answer;
        return acc;
      }, {} as any);

      return {
        id: ticket.id,
        name: ticket.name,
        document: ticket.document,
        email: ticket.email,
        event_id: ticket.event_id,
        event_name: ticket.event_name,
        event_date: ticket.event_date,
        modality: ticket.modality,
        category: ticket.category,
        kit: ticket.kit,
        ...questionsData,
      };
    });

    return data;
  }, [tickets]);

  const columnsCsv = useMemo<IColumn[]>(() => {
    const questionKeys = Array.from(
      new Set(
        dataCsv.flatMap((item) =>
          Object.keys(item).filter((key) =>
            /^questao \d+$|^resposta \d+$/.test(key)
          )
        )
      )
    );

    return [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Documento',
        selector: 'document',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Id do evento',
        selector: 'event_id',
      },
      {
        name: 'Nome do evento',
        selector: 'event_name',
      },
      {
        name: 'Data do evento',
        selector: 'event_date',
      },
      {
        name: 'Modalidade',
        selector: 'modality',
      },
      {
        name: 'Categoria',
        selector: 'category',
      },
      {
        name: 'Kit',
        selector: 'kit',
      },
      {
        name: 'Data compra',
        selector: 'buy_date',
      },
      {
        name: 'Nome comprador',
        selector: 'buyer_name',
      },
      {
        name: 'Fone comprador',
        selector: 'buyer_phone',
      },
      {
        name: 'email comprador',
        selector: 'buyer_email',
      },
      ...questionKeys.map((questionKey) => ({
        name: '',
        selector: questionKey,
      })),
    ];
  }, [dataCsv]);

  const handleClickExportCsv = useCallback(async () => {
    console.log(columnsCsv);
    console.log(dataCsv);
    return dataCsv;
  }, [columnsCsv, dataCsv]);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-sm-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Lista de ingressos</h1>
              {console.log(columnsCsv)}
              <ExportCsv
                data={tickets}
                columns={columnsCsv}
                fileName="Lista_de_ingressos"
                onClick={handleClickExportCsv}
              />
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              event
              statusTicket
              statusOptions={status}
            />
          </Filter>
          <div className="col-12 px-0">
            <div className="row">
              <TableList className="col-12 mt-3">
                <div className="bg-white pb-2">
                  <div className="bg-white overflow-auto">
                    <div className="">
                      <Table
                        className="mb-0 p-0 table"
                        columns={columns}
                        data={tickets}
                        pagination
                        totalData={tableData.total}
                        selectedPage={pageSelected}
                        // onChangePage={handleChangePage}
                      />
                    </div>
                  </div>
                </div>
              </TableList>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-filter"
        size="lg"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="justify-content-between align-items-center border-0 px-3 px-sm-5 pb-0 pt-0">
          <Modal.Title className="mb-0 mt-4">
            <h2 className="title mb-0">Perguntas</h2>
          </Modal.Title>
          <button
            type="button"
            onClick={handleClose}
            className="border-0 bg-transparent"
          >
            <KrClose size={20} color="#989898" />
          </button>
        </Modal.Header>
        <Modal.Body className="pt-0 px-3 px-sm-5">
          <div className="row">
            <div className="col-12">
              <hr />

              <TableListModal className="table-modal">
                <Table columns={columnsModal} data={questions} />
              </TableListModal>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 px-5" />
      </Modal>
    </Container>
  );
};

export default Users;
