/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MdCheck } from 'react-icons/md';
import {
  addYears,
  endOfDay,
  format,
  parseISO,
  startOfDay,
  subMonths,
} from 'date-fns';
import { TableList, Container, Filter, Status } from './styles';

import Table, { IColumn } from '~/components/Table';
import FiltersLists from '~/components/FiltersLists';
import ExportCsv from '~/components/ExportCsv';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { IOption } from '~/components/Select';

interface IDataItem {
  id: number;
  organizer: string;
  event: string;
  date: string;
  location: string;
  status: string;
  expected_qty: number;
  realized_qty: number;
  total: string;
  received: string;
  to_receive: string;
  active: boolean;
}

interface IItem {
  id: number;
}

interface IOrder {
  items: IItem[];
  total: string;
}

interface IBatchCategory {
  quantity: number;
  price: string;
}

interface IBatch {
  id: number;
  quantity: number;
  due_date: string;
  batchCategories: IBatchCategory[];
}

interface IArchiveEvent {
  archive: {
    archive_url: string;
  };
}

interface IEventData {
  id: number;
  name: string;
  description: string;
  date: string;
  slug: string;
  archivesEvents: IArchiveEvent[];
  batches: IBatch[];
  orders: IOrder[];
  address: {
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    complement: string;
    zip_code: string;
  };
  user: {
    name: string;
  };
  status: {
    name: string;
  };
}

interface IEventResponse {
  data: IEventData[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IStatus {
  id: number;
  name: string;
}

const Events: React.FC = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const [data, setData] = useState<IDataItem[]>([]);
  const [tableData, setTableData] = useState({
    from: 0,
    to: 0,
    total: 0,
    totalPage: 0,
  });
  const [eventName, setEventName] = useState<string | undefined>(undefined);
  const [statusId, setStatusId] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<IOption[]>([]);

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const dataStatus = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(dataStatus);
    });
  }, []);

  const handleLoadEvents = useCallback(
    async (
      pageData,
      name?: string,
      status_id?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<IEventResponse>('events/organizer', {
        params: {
          page: pageData,
          name,
          status_id,
          startDate: dateStart ? new Date(dateStart) : undefined,
          endDate: dateEnd ? new Date(dateEnd) : undefined,
          isList: true,
        },
      });
      const dataAux = response.data.data.map<IDataItem>((event) => {
        const allTickets = event.batches.reduce((previous, current) => {
          const totalTickets = current.batchCategories.reduce(
            (subPrevious, subCurrent) => subPrevious + subCurrent.quantity,
            0
          );
          return previous + totalTickets;
        }, 0);

        const purchases = event.orders.reduce(
          (previous, current) => previous + current.items.length,
          0
        );

        const location = `${event.address.street} n˚ ${event.address.number}, ${event.address.neighborhood} - ${event.address.city}/${event.address.state}, CEP: ${event.address.zip_code}`;

        const total = event.batches.reduce((previous, current) => {
          const totalTickets = current.batchCategories.reduce(
            (subPrevious, subCurrent) =>
              subPrevious + subCurrent.quantity * parseFloat(subCurrent.price),
            0
          );
          return previous + totalTickets;
        }, 0);

        const received = event.orders.reduce((previous, current) => {
          return previous + parseFloat(current.total);
        }, 0);

        const to_receive = received - received * 0.03;
        const koro = received * 0.03;

        return {
          id: event.id,
          organizer: event.user?.name,
          event: event.name,
          date: format(parseISO(event.date), 'dd/MM/yyyy'),
          location,
          status: event.status.name,
          expected_qty: allTickets,
          realized_qty: purchases,
          total: formatPrice(total),
          received: formatPrice(received),
          to_receive: formatPrice(to_receive),
          koro: formatPrice(koro),
          active: true,
        };
      });

      setData(dataAux);
      setTableData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        totalPage: response.data.pages,
      });
    },
    []
  );

  useEffect(() => {
    handleLoadEvents(
      1,
      undefined,
      undefined,
      startOfDay(subMonths(new Date(), 6)).toISOString(),
      endOfDay(addYears(new Date(), 1)).toISOString()
    );
  }, [handleLoadEvents]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
        sortable: false,
      },

      {
        name: 'Evento',
        selector: 'event',
        sortable: true,
      },
      {
        name: 'Data',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Local',
        selector: 'location',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        cell: (row) => (
          <Status status={row.status} className="text-white rounded-pill mb-0">
            {row.status}
          </Status>
        ),
      },
      {
        name: 'Qtd prevista',
        selector: 'expected_qty',
        sortable: true,
      },
      {
        name: 'Qtd realizada',
        selector: 'realized_qty',
        sortable: true,
      },
      {
        name: 'Meta',
        selector: 'total',
        sortable: true,
      },
      {
        name: 'Vendido',
        selector: 'received',
        sortable: true,
      },
      {
        name: 'A receber',
        selector: 'to_receive',
        sortable: true,
      },

      {
        name: 'Ativo',
        selector: 'active',
        sortable: true,
        cell: () => (
          <button type="button" className="pe-none btn-icons border-0">
            <MdCheck size={16} color="#fff" />
          </button>
        ),
      },
    ],
    []
  );

  const handleChangePage = useCallback(
    (page) => {
      if (page <= tableData.totalPage) {
        handleLoadEvents(page, eventName, statusId, startDate, endDate);
        setPageSelected(page);
      }
    },
    [
      endDate,
      eventName,
      handleLoadEvents,
      startDate,
      statusId,
      tableData.totalPage,
    ]
  );

  const handleSubmitFilters = useCallback(
    (formData) => {
      setEventName(formData.event);
      setStatusId(formData.status !== 'todos' ? formData.status : undefined);
      setStartDate(formData.dateStart);
      setEndDate(formData.dateEnd);
      handleLoadEvents(
        1,
        formData.event,
        formData.status !== 'todos' ? formData.status : undefined,
        formData.dateStart,
        formData.dateEnd
      );
    },
    [handleLoadEvents]
  );

  const handleClickExportCsv = useCallback(async () => {
    const response = await api.get<IEventData[]>('events/organizer', {
      params: {
        name: eventName,
        status_id: statusId,
        startDate,
        endDate,
      },
    });

    const dataAux = response.data.map<IDataItem>((event) => {
      const allTickets = event.batches.reduce((previous, current) => {
        const totalTickets = current.batchCategories.reduce(
          (subPrevious, subCurrent) => subPrevious + subCurrent.quantity,
          0
        );
        return previous + totalTickets;
      }, 0);

      const purchases = event.orders.reduce(
        (previous, current) => previous + current.items.length,
        0
      );

      const location = `${event.address.street} n˚ ${event.address.number}, ${event.address.neighborhood} - ${event.address.city}/${event.address.state}, CEP: ${event.address.zip_code}`;

      const total = event.batches.reduce((previous, current) => {
        const totalTickets = current.batchCategories.reduce(
          (subPrevious, subCurrent) =>
            subPrevious + subCurrent.quantity * parseFloat(subCurrent.price),
          0
        );
        return previous + totalTickets;
      }, 0);

      const received = event.orders.reduce((previous, current) => {
        return previous + parseFloat(current.total);
      }, 0);

      const to_receive = received - received * 0.03;
      const koro = received * 0.03;

      return {
        id: event.id,
        organizer: event.user?.name,
        event: event.name,
        date: format(parseISO(event.date), 'dd/MM/yyyy'),
        location,
        status: event.status.name,
        expected_qty: allTickets,
        realized_qty: purchases,
        total: formatPrice(total),
        received: formatPrice(received),
        to_receive: formatPrice(to_receive),

        active: true,
      };
    });

    return dataAux;
  }, [endDate, eventName, startDate, statusId]);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-sm-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Lista de eventos</h1>
              <ExportCsv
                data={data}
                columns={columns}
                fileName="Lista_de_eventos"
                onClick={handleClickExportCsv}
              />
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              event
              date
              statusEvent
              statusOptions={status}
            />
          </Filter>
          <div className="col-12 px-0">
            <div className="row">
              <TableList className="col-12 mt-3">
                <div className="bg-white pb-2">
                  <div className="bg-white overflow-auto">
                    <div className="">
                      <Table
                        className="mb-0 p-0 table"
                        columns={columns}
                        data={data}
                        pagination
                        totalData={tableData.total}
                        selectedPage={pageSelected}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  </div>
                </div>
              </TableList>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Events;
